.App {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
  background-color: black;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-button {
  background-color: black;
  color: white;
  border: none;
  padding: 8px 16px; /* Reducir el tamaño del padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px; /* Reducir el tamaño de la fuente */
  cursor: pointer;
  border-radius: 5px;
}

.auth-button:hover {
  background-color: #45a049;
}

.menu-button {
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.side-menu {
  position: fixed;
  top: 0;
  left: -200px; /* Oculta el menú inicialmente */
  width: 200px;
  height: 100%;
  background-color: #333;
  padding-top: 60px;
  transition: left 0.3s ease;
}

.side-menu a {
  display: block;
  color: white;
  padding: 10px;
  text-decoration: none;
}

.side-menu a:hover {
  background-color: #555;
}

.side-menu.open {
  left: 0; /* Muestra el menú cuando está abierto */
}

/* Añade este estilo para centrar el enlace en el header */
.center-link {
  flex-grow: 1;
  text-align: left;
}

/* Añade este estilo para el enlace de documentación */
.center-link a {
  margin-left: 10px; /* Ajusta el margen según sea necesario */
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif; /* Cambia 'Roboto' por la fuente que prefieras */
  transition: color 0.3s ease;
  margin-right: 2%;
}

.center-link a:hover {
  color: #45a049;
  text-decoration: underline;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}



