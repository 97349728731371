/* Añade estos estilos a tu hoja de estilos (App.css o Login.css) */
.login-container {
  max-width: 400px;
  margin: 5% auto 0; /* Margen superior del 15% */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-label {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333; /* Color del texto */
}

.login-label::before {
  content: attr(data-label); /* Utiliza el atributo data-label para personalizar el texto */
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}


.login-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #45a049;
}

.button-container {
  display: flex;
  gap: 10px;
}

.reset-button {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #ddd;
}

