.page {
  max-width: 98%; /* Reducir el ancho horizontal */
  margin: 0 auto;
  overflow-y: auto; /* Añadir scroll vertical */
  height: 100vh; /* Altura total de la ventana del navegador */
}

.documentation-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: left; /* Alineación izquierda */
}

.welcome-heading {
  font-size: 2em;
  color: #333;
}

.instruction {
  margin-top: 20px;
  font-size: 1.2em;
}

.section-heading {
  font-size: 1.5em;
  color: #007bff; /* Azul oscuro */
  margin-top: 30px;
}

.label-description {
  font-size: 1.1em;
}

.code-block {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  color: #333;
  white-space: pre-wrap; /* Asegura que el formato se mantenga */
}

.download-link {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.labels-list {
  list-style: none;
  padding: 0;
}

.labels-list li {
  background-color: #e0e0e0; /* Lighter background for each list item */
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.labels-list img {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
