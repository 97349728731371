/* src/components/Dynamic.css */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75rem;
  margin: auto;
  flex-grow: 1; /* El formulario crecerá para ocupar todo el espacio disponible */
}


/* Aplicar margen superior al primer formulario */
.form-container > .path-container:first-of-type {
  margin-top: 10px; /* Ajusta este valor según lo necesario */
}

.path-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.path-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.path-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  height: auto;
  overflow: hidden;
  transition: all 0.3s ease;
}

.path-content.hidden {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.path-content.visible {
  visibility: visible;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.parameters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.parameter {
  display: flex;
  gap: 10px;
}

.parameter-label {
  font-weight: bold;
  margin-bottom: 10px;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button, .remove-button {
  background-color: #6c757d; /* Un color gris suave */
  color: white;
  width: 8rem;
  height: 2.5rem;
}

.add-button {
  background-color: #6c757d;
}

.toggle-button:hover, .remove-button:hover, .add-button:hover {
  background-color: #5a6268; /* Un tono gris un poco más oscuro */
}

.submit-button {
  background-color: #17a2b8; /* Un color azul suave */
  color: white;
  margin-top: 20px;
  width: 20rem;
}

.reset-button {
  background-color: #5CBDBB; /* Un color azul suave */
  color: white;
  margin-top: 20px;
  width: 20rem;
}

.submit-button:hover {
  background-color: #138496; /* Un tono azul más oscuro */
}

.remove-button {
   width: 8rem;
   height: 2.5rem;
   background-color: #dc3545;
}

.remove-button-param {
  font-size: 0.8rem; /* Ajusta el tamaño del texto */
  padding: 0.1rem 0.2rem;
  width: 5rem;
  height: 2.5rem;
  background-color: #138496;
  margin-top:0.15rem;
  margin-left: 25rem;
}

.remove-button-param2 {
  font-size: 0.8rem; /* Ajusta el tamaño del texto */
  padding: 0.1rem 0.2rem;
  width: 5rem;
  height: 2.5rem;
  background-color: #138496;
  margin-top:0.15rem;
  margin-left: 7.4rem;
}

.left-align {
  text-align: left !important; /* Asegura que este estilo tenga prioridad */
}

.scrollable-container {
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
}

.p-paragraph {
            margin-top: 10px;
            text-align: center;
            color: #1E90FF; /* Un tono de azul más profesional */
            font-family: 'Arial', sans-serif; /* Tipo de letra más formal */
            font-size: 16px; /* Tamaño de fuente adecuado */
            line-height: 1.5; /* Mejor legibilidad */
            font-weight: 400; /* Peso de la fuente moderado */
}

.responses {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.response-body-type-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
   /* Ajusta el espacio a la izquierda del checkbox */
}

.responses {
  width: 70
  %; /* Ajusta el tamaño del select */
}

.remove-path-button {
  width: 100%;
}

.input-field-param {
  width: 10rem;
}
.input-field-param-custom {
  width: 17rem;
}

.input-field-param-select {
  width: 10rem;
}

.input-field-property-input {
  width: 30%;
}

.input-field-property-select {
  margin-left: 5px;
  margin-right: 5px;
  width: 15%;
}
.scrollable-container {
  height: 100vh; /* La altura ocupará el 100% del viewport height */
  overflow-y: auto; /* El scroll aparecerá automáticamente cuando sea necesario */
}

.response-item {
  border: 1px solid #007bff; /* Borde azul */
  border-radius: 5px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno */
  margin-bottom: 10px; /* Espaciado externo */
  background-color: #f1f1f1; /* Fondo gris claro */
  transition: all 0.3s ease;
}

.input-field-component-name {
    width:50%;
}

.response-item .input-field {
  width: 100%; /* Asegura que los campos de entrada ocupen el ancho completo */
  margin-bottom: 5px; /* Espacio entre los campos de entrada */
}

.response-body-type-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.remove-property-button {
  background-color: #dc3545; /* Color de fondo rojo */
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-path-button {
  background-color: #dc3545; /* Color de fondo rojo */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-path-button:hover {
  background-color: #c82333; /* Un tono rojo más oscuro */
}

.input-error {
  border: 1px solid red !important;
  background-color: #ffe6e6; /* Fondo ligeramente rojo para mayor visibilidad */
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Espacio entre los botones */
  margin-top: 20px; /* Espacio superior para separar del formulario */
}

.checkbox-required {
  transform: scale(0.5); /* Ajusta este valor para cambiar el tamaño del checkbox */
  margin-right: 5px; /* Ajusta este valor para controlar el espacio entre el checkbox y el botón */
}



