/* Añade estos estilos a tu hoja de estilos (App.css o Generate.css) */

.generate-project-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center; /* Centra el contenido del formulario */
}

.form-content {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

h2 {
  margin-top: 0; /* Elimina el margen superior del título */
}

form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos dentro del formulario */
}

label {
  margin-bottom: 5px;
  font-size: 14px;
}

input,
select {
  width: 100%; /* Ajusta el ancho de los campos al 100% */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.instruction-container {
  margin-top: 20px;
}

.instruction-container .instruction-list {
  list-style: none;
  padding: 0;
}

.instruction-container .instruction-list li {
  margin-bottom: 10px;
}

.instruction-container .instruction-list li b {
  color: #007bff; /* Color azul */
  margin-right: 5px;
}


