.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.welcome-heading {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 30px;
}

.premium-features h3 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.premium-features ul {
  list-style-type: none;
  padding: 0;
}

.premium-features li {
  font-size: 1.1em;
  color: #555;
  text-align: left;
  margin-bottom: 10px;
}
